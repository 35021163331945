import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongWhite } from "../../../components/Textstyles/Styles";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";

export const frontmatter = {
  title: "Día 68",
  week: "Semana 10",
  day: "20",
  month: "May",
  monthNumber: "05",
  date: "2020-05-20",
  path: "/cronologia/semana-10#dia-20-may/",
};

const Day68 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} disclaimer={true} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModFootnote>
          <strong>Nota: </strong>Algunas series publicadas por la ECDC están
          incompletas.
        </ModFootnote>
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="US"
          data="casosConfirmados"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 518 nuevos positivos y 110 personas fallecidas. Permanecen
          hospitalizadas 124.616 personas, 457 más que el día anterior, y
          reciben el alta. El número de casos ingresados en la UCI asciende a
          11.445, lo que supone un aumento de 31 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 53,8 %.
        </ModText>
        <ModDailyHighlight>
          El Pleno del Congreso autoriza la{" "}
          <StrongWhite>quinta prórroga del estado de alarma</StrongWhite>, hasta
          el 7 de junio, para hacer frente a la crisis sanitaria ocasionada por
          el COVID-19.
        </ModDailyHighlight>
        <ModText>
          El <strong>BOE</strong> ha publicado la{" "}
          <InlineLink link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-5142">
            Orden
          </InlineLink>{" "}
          que regula el uso obligatorio de mascarilla cuando no sea posible
          mantener la distancia interpersonal de 2 metros. De este modo, las
          personas de seis años en adelante deberán llevarla cuando se dé esa
          circunstancia en la vía pública, en espacios al aire libre y en
          cualquier espacio cerrado de uso público o que se encuentre abierto al
          público.
        </ModText>
        <ModImage
          src="/images/svg/mascarillas-FP2.svg"
          alt="mascarillas FFP2"
        />

        <ModText>
          En <strong>España</strong> hay puestos en marcha{" "}
          <strong>diez proyectos de vacunas</strong> en fase preclínica, con
          orientaciones diferentes. De ellos, hay tres liderados por
          investigadores del Consejo Superior de Investigaciones Científicas
          (CSIC), y otros siete de distintos centros de investigación que han
          sido seleccionados por el Fondo COVID-19 para recibir financiación.
        </ModText>
        <ModImage
          src="/images/svg/investigacion-vacuna.svg"
          alt="investigadores"
        />
        <ModText>
          Además, el <strong>Instituto de Salud Carlos III</strong> (ISCIII)
          tiene en marcha otro proyecto sobre vacunas, que va a dar apoyo al
          resto de investigaciones, proporcionando{" "}
          <strong>datos de la respuesta inmune</strong> y mejorando la
          composición de los posibles candidatos a vacuna.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day68;
