import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";

import Day64 from "./dia-16-may";
import Day65 from "./dia-17-may";
import Day66 from "./dia-18-may";
import Day67 from "./dia-19-may";
import Day68 from "./dia-20-may";
import Day69 from "./dia-21-may";
import Day70 from "./dia-22-may";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <ModMegaBanner bg="brand05">
        Entra en vigor la <StrongGold>quinta prórroga</StrongGold> del estado de
        alarma hasta el <StrongWhite>7 de junio</StrongWhite>
      </ModMegaBanner>
      <Day70 {...props} />
      <Day69 {...props} />
      <ModAlertBanner color="brand05">
        El Gobierno quiere solicitar la prórroga del estado de alarma para
        recorrer la <StrongWhite>desescalada de forma segura</StrongWhite>.
      </ModAlertBanner>
      <Day68 {...props} />
      <Day67 {...props} />
      <Day66 {...props} />
      <Day65 {...props} />
      <Day64 {...props} />
    </LayoutWeek>
  );
};

export default Week;
