import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 66",
  week: "Semana 10",
  day: "18",
  month: "may",
  monthNumber: "05",
  date: "2020-05-18",
  path: "/cronologia/semana-010#dia-18-may",
};

const Day66 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText></ModText>
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 498 nuevos positivos y 59 personas fallecidas. Permanecen
          hospitalizadas 125.233 personas, 123 más que el día anterior, y 800
          reciben el alta. El número de casos ingresados en la UCI asciende a
          11.437, lo que supone una disminución de 91 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 54,1 %.
        </ModText>
        <ModGraph name="guia-medidas" alt="guias para el covid-19" />

        <ModText>
          El Gobierno ha publicado una{" "}
          <InlineLink link="https://www.mscbs.gob.es/ssi/covid19/guia.htm">
            GUIA
          </InlineLink>{" "}
          con todas las medidas sociales y económicas aprobadas desde que
          comenzó la pandemia para hacer frente a COVID-19.
        </ModText>
        <ModText>
          Asimismo, también ha hecho pública una nueva actualización de la{" "}
          <InlineLink link="https://www.mapa.gob.es/es/ministerio/servicios/informacion/covid19/default.aspx">
            Guía Práctica para el sector agrario y pesquero
          </InlineLink>
          , que además de incluir toda la información relevante a COVID-19,
          incorpora otro tipo de contenido de interés.
        </ModText>
        <ModText>
          En el ámbito laboral, cerca de{" "}
          <strong>
            275.000 autónomos han recibido la devolución de la cuota{" "}
          </strong>{" "}
          abonada en marzo tras decretarse el estado de alarma, y más de 1,3
          millones tienen concedida la prestación extraordinaria. Esto supone
          una tasa de cobertura de más del 40% en 16 provincias, Ceuta y
          Melilla.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Documents/2020/15052020_TERRITORIOSQUEPASANDEFASE(2).pdf"
            name="Territorios que pasan a la Fase I y Fase II"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/covid-19/Paginas/mapa-fases-desescalada.aspx"
            name="Mapa de transición a la nueva normalidad"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/ssi/covid19/guia.htm"
            name="Guía de facilitación de acceso a las medidas urgentes en el ámbito social y económico para hacer frente al COVID-19"
          />
          <ReferenceRow
            link="http://www.puertos.es/es-es/Documents/RECOMENDACIONES_DE_PREVENCION_A_IMPLEMENTAR_A_BORDO_DE_BUQUES_Y_EN_LAS_ESTACIONES_MARITIMAS_DE_PASAJEROS.pdf"
            name="Recomendaciones de prevención en estaciones marítimas"
          />
          <ReferenceRow
            link="https://www.mapa.gob.es/es/ministerio/servicios/informacion/covid19/default.aspx"
            name="Nueva actualización de la Guía Práctica para el sector agrario y pesquero"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day66;
