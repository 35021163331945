import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 69",
  week: "Semana 10",
  day: "21",
  month: "may",
  monthNumber: "05",
  date: "2020-05-21",
  path: "/cronologia/semana-10#dia-21-may/",
};

const Day69 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={"2020-05-20"}
          country="UK"
          data="casosFallecidosDiario"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          {" "}
          En <strong>España</strong>, el balance del día se salda con el
          registro de 455 nuevos positivos y 52 personas fallecidas. Permanecen
          hospitalizadas 124.521 personas, -95 menos que el día anterior, y
          reciben el alta. El número de casos ingresados en la UCI asciende a
          11.454, lo que supone un aumento de 9 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 0,0 %.
        </ModText>
        <ModText>
          El <strong>Fondo COVID-19 del ISCIII</strong> reabre desde hoy hasta
          mañana viernes a las 15:00 la{" "}
          <strong>
            <InlineLink link="https://www.isciii.es/QueHacemos/Financiacion/Paginas/SolicitudExpresionesInteresCoVid19.aspx">
              recepción de propuestas de investigación
            </InlineLink>{" "}
          </strong>
          , enfocadas a factores ambientales y sociales y a proyectos sobre
          ámbito veterinario.
        </ModText>
        <ModImage
          src="/images/svg/transplantes.svg"
          alt="transplantes de organos"
        />

        <ModText>
          El <strong>Ministerio de Sanidad</strong> ha anunciado un plan
          especial post COVID-19 para{" "}
          <strong>recuperar la actividad de trasplantes</strong>. Desde que
          comenzó el estado de alarma, España ha logrado realizar 274
          trasplantes de 127 donantes.
        </ModText>
        <ModText>
          El Boletín Oficial del Estado ha publicado una{" "}
          <InlineLink link="https://www.boe.es/diario_boe/txt.php?id=BOE-A-2020-5192">
            Orden
          </InlineLink>{" "}
          que modifica las instrucciones publicadas el pasado 3 de mayo sobre la
          utilización de mascarillas en los distintos medios de transporte y los
          requisitos para garantizar una movilidad segura de conformidad con el
          plan para la transición hacia una nueva normalidad.
        </ModText>
        <ModGraph
          name="transporte-publico"
          alt="Transporte publico con mascarillas covid-19"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day69;
